import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "../pages/login/Login";
import { Reset } from "../pages/ResetPassword/Reset";

export const Auth: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/reset-password" element={<Reset />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

