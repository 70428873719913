import API from '../../../hooks/api';
import { ALL } from '../../../utils/Constants';
import { APP_LOANS_V2_URL } from '../../../utils/Endpoints';
import { filters, LoanLookUpInfo, LoanLookUpInfoData } from './ScreenHelper';
const { REACT_APP_BASE_URL } = process.env;

export const getLoansLookupInfo = async (
    data: filters,
    callBack: (success: boolean, data: LoanLookUpInfoData, errorMsg: string | null) => void = () => { }
) => {
    const paramsObj = {
        page: (data.page ?? 1),
        size: data.pageSize ?? 10,
        searchText: data.search,
        loadAllDocuments:data.loadAllDocuments
    }

    //Send requestType and stage only if they are not ALL
    if (data.requestType !== ALL) {
        paramsObj['requestType'] = data.requestType
    }

    if (data.stage !== ALL) {
        paramsObj['stage'] = data.stage;
    }

    await API.get(REACT_APP_BASE_URL + APP_LOANS_V2_URL, {
        params: paramsObj
    })
        .then((res) => {
            const data = res.data;
            callBack(true, data, null);
            return res.data;
        }).catch((err) => {
            callBack(false, null, err);
        });
}

