import API from '../../../hooks/api';
import { APP_INTRA_STATEMENTS_V2_URL } from '../../../utils/Endpoints';
import { filters, IntraStatement, IntraStatementList } from './ScreenHelper';
const { REACT_APP_BASE_URL } = process.env;

export const getIntraStatements = async (
    data: filters,
    callBack: (success: boolean, data: IntraStatementList, errorMsg: string | null) => void = () => { }
) => {
    await API.get(REACT_APP_BASE_URL + APP_INTRA_STATEMENTS_V2_URL, {
        params: {
            transactionType: data.transactionType === "ALL" ? "" : data.transactionType,
            page: (data.page ?? 1),
            size: (data.size ?? 10),
            searchText: data.search,
            loadAllDocuments: data.loadAllDocuments
        }
    })
        .then((res) => {
            const data: IntraStatementList = res.data;
            callBack(true, data, null);
            return res.data;
        }).catch((err) => {
            callBack(false, null, err);
        });
}



