import { setErrorMsg } from "../../../redux/reducers/settingsSlice";
import { store } from "../../../redux/store";

export interface UserResetData {
  email: string;
  oldPassword: string;
  newPassword: string;
}

// Reset confirm Popup
export const confirmReset = ({ message }) => {
  store.dispatch(
    setErrorMsg({
      icon: null,
      acceptBtnTitle: "OK",
      title: message,
      acceptBtnAction: () => {
        window.location.href = "/";
      },
    })
  );
};
