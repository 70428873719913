import { NavigateFunction } from "react-router-dom";
import { setErrorMsg } from "../../../redux/reducers/settingsSlice";
import { store } from "../../../redux/store";
import { CustomersTracker } from "../CustomersTracker";

export interface Coordinates {
  lat: Number;
  lng: Number;
}

export interface filters {
  date?: string;
  bank?: string;
  status?: string;
  page?: number;
  search?: string;
}

export interface geoMarker {
  keycloakId: string;
  id: string;
  city: string;
  country: string;
  deviceName: string;
  deviceId: string;
  latitude: string;
  longitude: string;
  phoneIpAddress: string;
  operationType: string;
  createdAt: string;
}

export interface CustomerTrackingProps {
  locationData?: stateType;
  mapHeight?: string;
}

export interface stateType {
  data: geoMarker[];
  points: { lat: number; lng: number }[];
}

// Printing pagination items
// Printing pagination items
export const paginationElemnts = (
  dataArray: geoMarker[],
  currentPage: number,
  length: number,
  changePage: (i: number) => void
): JSX.Element[] => {
  let pages: JSX.Element[] = [];
  let totalPage = Math.ceil(length / 10);

  const first = (
    <li key={1} className={"page-item" + (1 === currentPage ? " active" : "")}>
      <button className="btn btn-link" onClick={(e) => changePage(1)}>
        1
      </button>
    </li>
  );
  const last = (
    <li
      key={totalPage}
      className={"page-item" + (totalPage === currentPage ? " active" : "")}
    >
      <button className="btn btn-link" onClick={(e) => changePage(totalPage)}>
        {totalPage}
      </button>
    </li>
  );

  if (currentPage - 1 > 1) pages.push(first);
  for (let i = currentPage - 1; i <= currentPage + 1; i++) {
    if (i > 0 && i <= totalPage) {
      const element = (
        <li
          key={i.toString()}
          className={"page-item" + (i === currentPage ? " active" : "")}
        >
          <button className="btn btn-link" onClick={(e) => changePage(i)}>
            {i}
          </button>
        </li>
      );
      pages.push(element);
    }
    if (i === currentPage && dataArray?.length < 10) break;
  }
  if (totalPage - currentPage > 1) pages.push(last);

  return pages;
};

export const showTrackMap = (data: geoMarker, navigate: NavigateFunction) => {
  const geoMarkers = [data];
  let points = geoMarkers.map((point) => {
    return {
      lat: Number(point.latitude),
      lng: Number(point.longitude),
    };
  });
  let payload = { data: geoMarkers, points };
  store.dispatch(
    setErrorMsg({
      icon: null,
      cancelBtnTitle: points.length ? "Full map" : "",
      cancelBtnAction: () => {
        navigate("/customers/map", { state: payload });
      },
      title: "Customer map",
      message: <CustomersTracker locationData={payload} />,
    })
  );
};
