import API from '../../../hooks/api';
import { filters, AMLDataList, FinanacialData } from './ScreenHelper';
import {  getFullURL, GET_AML_URL, AML_FINANCING_DATA } from '../../../utils/Endpoints';
import { AML_STATUS_URL } from '../../../utils/Endpoints';
const { REACT_APP_BASE_URL } = process.env;

export const getAML = async (
        data: filters,
        callBack: (success: boolean, data: AMLDataList, errorMsg: string | null) => void = () => {}
    ) => {
    const paramsObj = getParamObj(data);
    await API.get(getFullURL(GET_AML_URL), {params: paramsObj})
    .then((res) => {
        const data: AMLDataList = res.data;
        callBack(true, data, null);
        return res.data;
    }).catch((err) => {
        callBack(false, null, err);
    });
}

export const updateAMLStatus = async (
    data,
    callBack: (success: boolean, errorMsg: string | null) => void = () => {}
) => {
    await API.put(REACT_APP_BASE_URL + AML_STATUS_URL, data)
        .then((res) => {
            callBack(true, null);
            return res.data;
        }).catch((err) => {
            callBack(false, err);
        });
}

export const requestFinancialData = async (financialId, callBack: (success: boolean, data, errorMsg: string | null) => void = () => {}) => {
    await API.get(AML_FINANCING_DATA + financialId)
    .then((res) => {
        const data:FinanacialData = res.data;
        callBack(true, data,  null);
        return res.data;
    }).catch((err) => {
        callBack(false, null, err);
    });
}

function getParamObj(data: filters) {
    const paramsObj = {
        page: data.page ?? 1,
        size: data.pageSize ?? 10,
        searchByGovId: data.nationalID ?? "",
        loadAllRecords: data.loadAllRecords ?? false,
        amlockStage: (data.AMLStatus === "ALL") ? "" : data.AMLStatus,
    };
    return paramsObj;
}

