import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";

import { useTranslation } from "react-i18next";
import { localStorageHelper } from "../../hooks/localStorageHelper";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomerRequest,
  IbanStatus,
  IbanStatusType,
} from "../Dashboard/hook/ScreenHelper";
import { store } from "../../redux/store";
import { setErrorMsg } from "../../redux/reducers/settingsSlice";
import { updateStatus } from "./hook/API";

interface stateType {
  data: CustomerRequest;
}
export const Certificate: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const customerModel = (location.state as stateType).data;

  const changeIbanStatus = (
    reqId: string,
    status: IbanStatusType,
    showReasonError?: boolean
  ) => {
    const isRejected = status === IbanStatus.REJECTED;
    let reason = "";
    store.dispatch(
      setErrorMsg({
        title: t("pages.iban.warningTitle"),
        cancelBtnTitle: t("no"),
        acceptBtnTitle: t("yes"),
        acceptBtnAction: () => {
          if (isRejected && (!reason || !reason.trim())) {
            setTimeout(() => {
              changeIbanStatus(reqId, status, true);
            }, 0);
          } else {
            updateStatus(
              { requestId: reqId, ibanStatus: status, reason },
              (success, _) => success && navigate("/")
            );
          }
        },
        modalContentClass: "small",
        message: (
          <div className="mb-3" style={{ textAlign: "start" }}>
            <p>{t("pages.iban.warningMessage")}</p>
            {isRejected && (
              <>
                <label htmlFor="recipient-name" className="col-form-label">
                  {t("inputs.loans.reason")}:
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter reason"
                  onChange={(e) => {
                    reason = e.target.value;
                  }}
                />
              </>
            )}
            {showReasonError && (
              <p className="text-danger">{t("ibanRejectReasonRequired")}</p>
            )}
          </div>
        ),
      })
    );
  };

  return (
    <>
      <div className="box_container">
        <div className="row g-3">
          <div className="col-md-12">
            <button
              className="btn btn-icon-only btn-rounded btn-back rotate-275"
              onClick={() => navigate("/")}
            >
              <i className="fas fa-arrow-up" aria-hidden="true"></i>
            </button>
          </div>
          <div className="col-md-6">
            <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
              <div className="icon icon-shape icon-lg bg-gradient-primary shadow text-center border-radius-lg me-3">
                <i
                  className="fas fa-money-check opacity-10"
                  aria-hidden="true"
                ></i>
              </div>
              <div>
                <h6 className="mb-1 text-sm">
                  <strong>{t("inputs.iban")}</strong>
                </h6>
                <h6 className="mb-0">{customerModel.iban}</h6>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
              <div className="icon icon-shape icon-lg bg-gradient-primary shadow text-center border-radius-lg me-3">
                <i
                  className="fas fa-user-tie opacity-10"
                  aria-hidden="true"
                ></i>
              </div>
              <div>
                <h6 className="mb-1 text-sm">
                  <strong>
                    {t("inputs.customer")}, {t("inputs.customerId")}
                  </strong>
                </h6>
                <h6 className="mb-0">
                  {customerModel.customerName},{" "}
                  <span className="mx-1">{customerModel.customerId}</span>{" "}
                </h6>
                {customerModel.arabicName ? (
                  <h6 className="mb-0">{customerModel.arabicName}</h6>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
              <div className="icon icon-shape icon-lg bg-gradient-primary shadow text-center border-radius-lg me-3">
                <i
                  className="fas fa-landmark opacity-10"
                  aria-hidden="true"
                ></i>
              </div>
              <div>
                <h6 className="mb-1 text-sm">
                  <strong>{t("inputs.bank")}</strong>
                </h6>
                <h6 className="mb-0">{customerModel.bankName}</h6>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
              <div className="icon icon-shape icon-lg bg-gradient-primary shadow text-center border-radius-lg me-3">
                <i className="fas fa-info opacity-10" aria-hidden="true"></i>
              </div>
              <div>
                <h6 className="mb-1 text-sm">
                  <strong>{t("inputs.status")}</strong>
                </h6>
                <h6 className="mb-0">
                  {t("status." + customerModel.ibanStatus)}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="card card-body border-0 card-plain border-radius-lg d-flex align-items-center flex-row"
              style={{ height: 450, background: "#f8f9fa" }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={
                    process.env.REACT_APP_BASE_URL +
                    "/iban-operation/certificate/view/" +
                    customerModel.requestId
                  }
                  httpHeaders={{
                    Authorization: `Bearer ${
                      localStorageHelper.load("profile").access_token
                    }`,
                  }}
                />
              </Worker>
            </div>
          </div>
          {customerModel.ibanStatus === IbanStatus.TO_BE_VERIFIED && (
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-warning btn-lg px-5 py-3"
                  onClick={() =>
                    changeIbanStatus(
                      customerModel.requestId,
                      IbanStatus.VERIFIED
                    )
                  }
                >
                  Approve
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-lg px-5 py-3"
                  onClick={() =>
                    changeIbanStatus(
                      customerModel.requestId,
                      IbanStatus.REJECTED
                    )
                  }
                >
                  Reject
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
