import moment from 'moment';
import API from '../../../hooks/api';
import { localStorageHelper } from '../../../hooks/localStorageHelper';
import { PROFILE_KEY, ROLES_KEY, SESSOIN_EXPIRY_KEY } from '../../../utils/Constants';
import { parseJwt } from '../../../utils/Helpers';
import { userLoginData } from './ScreenHelper';

export const loginAPI = async (
        data: userLoginData,
        callBack: (success: boolean, errorMsg: string | null) => void = () => {}
    ) => {
    await API.post("/operations/login", {
        uid: data.email,
        password: data.password,
    })
    .then((res) => {
        localStorageHelper.store(PROFILE_KEY, res.data);
        const tokenData = parseJwt(res.data.access_token);
        localStorageHelper.store(SESSOIN_EXPIRY_KEY, moment().clone().add(res?.data?.expires_in, 'seconds'));
        // localStorageHelper.store(SESSOIN_EXPIRY_KEY, tokenData.exp);
        localStorageHelper.store(ROLES_KEY, tokenData.resource_access['qf-connect-api'].roles)
        
        callBack(true, null);
    }).catch((err) => {
        callBack(false, err);
    });
}
