import API from '../../../hooks/api';
import {
    APP_REJECTIONS_TRACKING_SUMMARY_URL,
    APP_TRACKING_LOAN_REJECTIONS_URL,
    APP_TRACKING_REGISTRATION_REJECTIONS_URL,
    CUSTOMER_LIST_URL
} from '../../../utils/Endpoints';
import { CustomerData, ReportFilter, ReportSummaryModel, filters } from './Screenhelper';
const { REACT_APP_BASE_URL } = process.env;

export const getRejectionTrackingSummary = async (callBack: (success: boolean, data: ReportSummaryModel, errorMsg: string | null) => void = () => { }
) => {
    await API.get(REACT_APP_BASE_URL + APP_REJECTIONS_TRACKING_SUMMARY_URL, {
    })
        .then((res) => {
            const data = res.data;
            callBack(true, data, null);
            return res.data;
        }).catch((err) => {
            callBack(false, {}, err);
        });
}

export const getRejectedLoans = async (data: filters,
    callBack: (success: boolean, data: CustomerData, errorMsg: string | null) => void = () => { }
) => {
    const paramsObj = {
        page: (data.page ?? 1),
        size: 10,
        loadAllDocuments: data.loadAllDocuments
    }

    await API.get(REACT_APP_BASE_URL + APP_TRACKING_LOAN_REJECTIONS_URL, {
        params: paramsObj
    })
        .then((res) => {
            const data = res.data;
            callBack(true, data, null);
            return res.data;
        }).catch((err) => {
            callBack(false, null, err);
        });
}

export const getRejectedRegistration = async (data: filters,
    callBack: (success: boolean, data: CustomerData, errorMsg: string | null) => void = () => {}
    ) => {
    let params:filters = {
        page: (data.page ?? 1),
        size: data.size ?? 10,
        loadDeactivatedCustomersOnly: data.loadDeactivatedCustomersOnly ?? false,
        loadAllDocuments:data.loadAllDocuments
    }

    if(data.city && data.city!=""){
        params = {...params, city: data.city}
    }
    if(data.country && data.country!=""){
        params = {...params, country: data.country}
    }
    if(data.reason && data.reason!=""){
        params = {...params, reason: data.reason}
    }

    await API.get(REACT_APP_BASE_URL + APP_TRACKING_REGISTRATION_REJECTIONS_URL, {
        params: params
    })
    .then((res) => {
        const data = res.data;
        callBack(true, data, null);
        return res.data;
    }).catch((err) => {
        callBack(false, null, err);
    });
}

export const getDeactivationReport = async (
        data: filters,
        callBack: (success: boolean, data: CustomerData, errorMsg: string | null) => void = () => {}
    ) => {
    
    var params:filters = {
        page: (data.page ?? 1),
        size: data.size ?? 10,
        loadDeactivatedCustomersOnly: data.loadDeactivatedCustomersOnly ?? false,
        loadAllDocuments:data.loadAllDocuments
    }

    if(data.city && data.city!=""){
        params = {...params, city: data.city}
    }
    if(data.country && data.country!=""){
        params = {...params, country: data.country}
    }
    if(data.reason && data.reason!=""){
        params = {...params, reason: data.reason}
    }
    
    await API.get(REACT_APP_BASE_URL + CUSTOMER_LIST_URL, {params: params})
    .then((res) => {
        const data = res.data;
        callBack(true, data, null);
        return res.data;
    }).catch((err) => {
        callBack(false, null, err);
    });
}

