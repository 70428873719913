import { Chart as ChartJS, registerables } from "chart.js";
import { memo, useMemo } from "react";
import { Bar, Line } from "react-chartjs-2";
import "./index.css";
import { ICardModel } from "./ScreenHelper";

ChartJS.register(...registerables);

const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    ticks: { display: false },
    scales: {
      y: {
        min: -1,
        max: 25,
        ticks: {
          stepSize: 1,
        },
      },
    },
  },
};

// const legend = {
//   display: true,
//   position: "bottom",
//   labels: {
//     fontColor: "#323130",
//     fontSize: 14,
//   },
// };

// const chartOptionsExceptions = {
//   plugins: {
//     ticks: { display: false },
//     scales: {
//       y: {
//         min: -1,
//         max: 25,
//         ticks: {
//           stepSize: 1,
//         },
//       },
//     },
//   },
// };

//Iterate through the data and get the unique time
const getUniqueTime = (data) => {
  let uniqueTime = [];
  (data || []).forEach((el) => {
    (el.items || []).forEach((item) => {
      if (!uniqueTime.includes(item.time)) {
        uniqueTime.push(item.time);
      }
    });
  });
  return uniqueTime;
};

const numberSort = (a, b) => {
  return a - b;
};

const Card = (props: ICardModel) => {
  const { count, title, incrementPer, data, showPerStats } = props.cardModel;
  const activeUserData = {
    labels: data?.hourly?.sort((a, b) => a.time - b.time).map((el) => el.time),
    datasets: [
      {
        label: "Active Users",
        data: data?.hourly?.map((el) => el.count),
        backgroundColor: "#2B3467",
        borderRadius: 4,
      },
    ],
  };

  const RegistrationData = useMemo(() => {
    const regItems =
      data?.registrationSummary?.hourlyRegistration?.map((el) => el.time) || [];
    const inCompleteRegItems =
      data?.incompleteRegistrationSummary?.hourlyRegistration?.map(
        (el) => el.time
      ) || [];
    const registrationLabels = Array.from(
      new Set([...regItems, ...inCompleteRegItems])
    );
    const regChartCount = registrationLabels.map((time) => {
      const regItem = data?.registrationSummary?.hourlyRegistration?.find(
        (el) => el.time === time
      );
      const inCompleteRegItem =
        data?.incompleteRegistrationSummary?.hourlyRegistration?.find(
          (el) => el.time === time
        );
      return {
        time,
        regCount: regItem ? regItem.count : 0,
        inCompleteRegItem: inCompleteRegItem ? inCompleteRegItem.count : 0,
      };
    });

    return {
      labels: registrationLabels.sort(numberSort),
      datasets: [
        {
          label: "Incomplete",
          backgroundColor: "#2B3467",
          hoverBackgroundColor: "rgba(75,192,192,0.6)",
          hoverBorderColor: "rgba(75,192,192,1)",
          data: regChartCount?.map((el) => el.inCompleteRegItem),
          borderRadius: 3,
        },
        {
          label: "Completed",
          backgroundColor: "#2F58CD",
          hoverBackgroundColor: "rgba(255,99,132,0.6)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: regChartCount?.map((el) => el.regCount),
          borderRadius: 3,
        },
      ],
    };
  }, [data]);

  const UserStatisticsData = useMemo(() => {
    const activeUserSummary =
      data?.activeUserSummary?.hourlyCount?.map((el) => el.time) || [];
    const blockedUserSummary =
      data?.blockedUserSummary?.hourlyCount?.map((el) => el.time) || [];
    const deactiveUserSummary =
      data?.deactiveUserSummary?.hourlyCount?.map((el) => el.time) || [];

    const userSummary = Array.from(
      new Set([
        ...activeUserSummary,
        ...blockedUserSummary,
        ...deactiveUserSummary,
      ])
    );

    const userChartCount = userSummary.map((time) => {
      const activeUserSummary = data?.activeUserSummary?.hourlyCount?.find(
        (el) => el.time === time
      );
      const blockedUserSummary = data?.blockedUserSummary?.hourlyCount?.find(
        (el) => el.time === time
      );
      const deactiveUserSummary = data?.deactiveUserSummary?.hourlyCount?.find(
        (el) => el.time === time
      );

      return {
        time,
        activeUserSummary: activeUserSummary ? activeUserSummary.count : 0,
        blockedUserSummary: blockedUserSummary ? blockedUserSummary.count : 0,
        deactiveUserSummary: deactiveUserSummary
          ? deactiveUserSummary.count
          : 0,
      };
    });

    return {
      labels: userSummary.sort(numberSort),
      datasets: [
        {
          label: "Active User",
          backgroundColor: "#2B3467",
          hoverBackgroundColor: "rgba(75,192,192,0.6)",
          hoverBorderColor: "rgba(75,192,192,1)",
          data: userChartCount?.map((el) => el.activeUserSummary),
          borderRadius: 3,
        },
        {
          label: "Deactive User",
          backgroundColor: "#2F58CD",
          hoverBackgroundColor: "rgba(255,99,132,0.6)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: userChartCount?.map((el) => el.deactiveUserSummary),
          borderRadius: 3,
        },
        {
          label: "Block User",
          backgroundColor: "#2F5712",
          hoverBackgroundColor: "rgba(255,99,105,0.6)",
          hoverBorderColor: "rgba(255,99,105,1)",
          data: userChartCount?.map((el) => el.blockedUserSummary),
          borderRadius: 3,
        },
      ],
    };
  }, [data]);

  const loanCountData = useMemo(() => {
    const loanCountDataLabels = getUniqueTime(data?.data) || [];
    const regChartCount = loanCountDataLabels
      .map((time) => {
        const completedItems = data?.data?.find(
          (el) => el.type === "completed"
        );
        const pendingItems = data?.data?.find((el) => el.type === "pending");
        const rejectedItems = data?.data?.find((el) => el.type === "rejected");

        const completedItem = completedItems?.items.find(
          (el) => el.time === time
        );
        const inCompleteRegItem = pendingItems?.items.find(
          (el) => el.time === time
        );
        const rejectedItem = rejectedItems?.items.find(
          (el) => el.time === time
        );

        return {
          time,
          completed: completedItem ? completedItem.count : 0,
          pending: inCompleteRegItem ? inCompleteRegItem.count : 0,
          rejected: rejectedItem ? rejectedItem.count : 0,
        };
      })
      .sort((a, b) => a.time - b.time);

    return {
      labels: loanCountDataLabels.sort(numberSort),
      datasets: [
        {
          label: "Pending",
          backgroundColor: "#2B3467",
          hoverBackgroundColor: "rgba(75,192,192,0.6)",
          hoverBorderColor: "rgba(75,192,192,1)",
          data: regChartCount?.map((el) => el.pending),
          borderRadius: 3,
        },
        {
          label: "Completed",
          backgroundColor: "#2F58CD",
          hoverBackgroundColor: "rgba(255,99,132,0.6)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: regChartCount?.map((el) => el.completed),
          borderRadius: 3,
        },
        {
          label: "Rejected",
          backgroundColor: "#4E31AA",
          hoverBackgroundColor: "rgba(255,99,132,0.6)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: regChartCount?.map((el) => el.rejected),
          borderRadius: 3,
        },
      ],
    };
  }, [data]);

  const loanCategoryData = useMemo(() => {
    const loanCategoryLabels = getUniqueTime(data?.data) || [];
    const loanCategoryChartData = loanCategoryLabels.map((time) => {
      const cashLoanStages = [
        "CREATED",
        "VERIFIED",
        "COMMODITY_PURCHASED",
        "APP_CUSTCARED",
        "CALL_INITIALIZED",
        "CALL_APPROVED",
        "NAFAES_SALES_ORDERED",
        "CONTRACT_SIGNED",
        "SANAD_CREATED",
        "DISBURSED",
      ];
      const categoryChartData = { time };

      cashLoanStages.forEach((stage) => {
        const stageItems = data?.data?.find((el) => el.type === stage);
        const stageCount = stageItems?.items.find((el) => el.time === time);
        categoryChartData[stage] = stageCount ? stageCount.count : 0;
      });

      return categoryChartData;
    });

    return {
      labels: loanCategoryLabels.sort(numberSort),
      datasets: [
        {
          label: "CREATED",
          hoverBackgroundColor: "rgba(75,192,192,0.6)",
          hoverBorderColor: "rgba(75,192,192,1)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          data: loanCategoryChartData?.map((el) => el["CREATED"]),
          borderRadius: 3,
        },
        {
          label: "VERIFIED",
          backgroundColor: "#3E54AC",
          // borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.6)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: loanCategoryChartData?.map((el) => el["VERIFIED"]),
          borderRadius: 3,
        },
        {
          label: "COMMODITY_PURCHASED",
          backgroundColor: "#655DBB",
          // borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.6)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: loanCategoryChartData?.map((el) => el["COMMODITY_PURCHASED"]),
          borderRadius: 3,
        },
        {
          label: "APP_CUSTCARED",
          backgroundColor: "#3A1078",
          // borderWidth: 1,
          hoverBackgroundColor: "rgba(75,192,192,0.6)",
          hoverBorderColor: "rgba(75,192,192,1)",
          data: loanCategoryChartData?.map((el) => el["APP_CUSTCARED"]),
          borderRadius: 3,
        },
        {
          label: "CALL_INITIALIZED",
          backgroundColor: "#2F58CD",
          // borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.6)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: loanCategoryChartData?.map((el) => el["CALL_INITIALIZED"]),
          borderRadius: 3,
        },
        {
          label: "CALL_APPROVED",
          backgroundColor: "#A61F69",
          // borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.6)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: loanCategoryChartData?.map((el) => el["CALL_APPROVED"]),
          borderRadius: 3,
        },
        {
          label: "NAFAES_SALES_ORDERED",
          backgroundColor: "#D9ACF5",
          // borderWidth: 1,
          hoverBackgroundColor: "rgba(75,192,192,0.6)",
          hoverBorderColor: "rgba(75,192,192,1)",
          data: loanCategoryChartData?.map((el) => el["NAFAES_SALES_ORDERED"]),
          borderRadius: 3,
        },
        {
          label: "CONTRACT_SIGNED",
          backgroundColor: "#5D3891",
          // borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.6)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: loanCategoryChartData?.map((el) => el["CONTRACT_SIGNED"]),
          borderRadius: 3,
        },
        {
          label: "SANAD_CREATED",
          backgroundColor: "#6096B4",
          // borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.6)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: loanCategoryChartData?.map((el) => el["SANAD_CREATED"]),
          borderRadius: 3,
        },
      ],
    };
  }, [data]);

  const paymentCountDetails = useMemo(() => {
    const paymentItemTimes =
      data?.data?.map((el) => el.time).sort((a, b) => a - b) || [];
    const paymentLabels = Array.from(new Set([...paymentItemTimes]));
    const paymentChartCount = paymentLabels.map((time) => {
      const regItem = data?.data?.find((el) => el.time === time);
      return {
        time,
        paymentCount: regItem ? regItem.count : 0,
      };
    });

    return {
      labels: paymentLabels?.sort(numberSort),
      datasets: [
        {
          label: "Payment Count",
          data: paymentChartCount?.map((el) => el.paymentCount),
          backgroundColor: "#2B3467",
          borderRadius: 4,
        },
      ],
    };
  }, [data]);

  const ChartRender = () => {
    if (title === "Login Counts")
      return <Bar data={activeUserData} options={chartOptions} />;
    if (title === "Payment Counts")
      return <Line data={paymentCountDetails} options={chartOptions} />;
    if (title === "Registration")
      return <Bar data={RegistrationData} options={chartOptions} />;
    if (title === "Loan Count")
      return <Bar data={loanCountData} options={chartOptions} />;
    if (title === "Loan Category") return <Line data={loanCategoryData} />;
    if (title === "User Statistics")
      return <Bar data={UserStatisticsData} options={chartOptions} />;
  };

  return (
    <div className="card graph_Card">
      <div className="card-body">
        <div className="row">
          <span className="col-8 text-left">
            <h6>{title}</h6>
            <h5 className="dashBoard_header">{count ?? 0}</h5>
          </span>
          {showPerStats ? (
            <div className="col-4">
              <span className="text-right">{incrementPer ?? 0}%</span>
              <span style={{ margin: "5px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-up-circle-fill"
                  viewBox="0 0 16 16"
                  style={{ color: incrementPer < 100 ? "red" : "green" }}
                >
                  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                </svg>
              </span>
              <h6 className="minor_header">vs Previous day</h6>
            </div>
          ) : null}
        </div>
        <ChartRender />
      </div>
    </div>
  );
};

export default memo(Card);
