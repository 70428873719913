import API from '../../../hooks/api';
import { UserResetData } from './ScreenHelper';

export const resetAPI = async (
        data: UserResetData,
        callBack: (success: boolean, errorMsg: string | null) => void = () => {}
    ) => {
    await API.post("/operations/reset-password", {
        uid: data.email, 
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
    })
    .then((res) => {
        callBack(true, null);
    }).catch((err) => {
        callBack(false, err);
    });
}
