import React from "react";

const SidebarPlaceholder = () => {
  return (
    <>
      <div className="mb-3 sidebar_box placeholder-glow">
        <h6 className="px-4">
          <span className="placeholder col-12 rounded"></span>
        </h6>
        <hr className="w-100" />
        <div className="row mb-4 px-4">
          <div className="col-6">
            <span className="placeholder col-12 rounded"></span>
          </div>
          <div className="col-6 fw-bold">
            <span className="placeholder col-12 rounded"></span>
          </div>
        </div>
        <div className="row mb-4 px-4">
          <div className="col-6">
            <span className="placeholder col-12 rounded"></span>
          </div>
          <div className="col-6 fw-bold">
            <span className="placeholder col-12 rounded"></span>
          </div>
        </div>
        <div className="row mb-4 px-4">
          <div className="col-6">
            <span className="placeholder col-12 rounded"></span>
          </div>
          <div className="col-6 fw-bold">
            <span className="placeholder col-12 rounded"></span>
          </div>
        </div>
        <div className="row mb-4 px-4">
          <div className="col-6">
            <span className="placeholder col-12 rounded"></span>
          </div>
          <div className="col-6 fw-bold">
            <span className="placeholder col-12 rounded"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarPlaceholder;
