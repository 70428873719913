import { setErrorMsg } from '../../../redux/reducers/settingsSlice';
import { store } from './../../../redux/store';

export interface AppSetting {
    id?: string;
    settingName?: string;
    settingValue?: string;
}


// confirm popup
export const confirmation = ({message}) => {
    store.dispatch(setErrorMsg({
        icon: null,
        title: "Confirmation",
        acceptBtnTitle: "Ok",
        message: message
    }))
}