const { REACT_APP_BASE_URL } = process.env;

//Endpoint for fetching the app settings.
export const APP_SETTINGS_V2_URL = "/operations-order/v2/app/settings";

//Endpoint for updating the app settings.
export const APP_SETTINGS_V1_URL = "/operations-order/app/settings";


//CUSTOMER endpoints.
//Endpoint to fetch the customer list.
export const CUSTOMER_LIST_URL = "/customer/v2/list";

//Endpoint to deactivate the customer
export const CUSTOMER_DEACTIVATE_URL = "/customer/deactivate";

//Endpoint for updating the cusotmer block status.
export const CUSTOMER_UPDATE_BLOCK_STATUS_URL = "/operations/blocked-status";


//Endpoint for fetching the intra anb statements.
export const APP_INTRA_STATEMENTS_V2_URL = "/operations-order/v2/intra/statements";

//Endpoint for fetching the loans status.
export const APP_LOANS_V2_URL = "/operations-order/v2/loans";

//Endpoint for fetching the credit quality data.
export const APP_CREDIT_QUALITY_API_V2_URL = "/operations-order/v2/getQualityData";

//Endpoint for fetching the account balance
export const APP_ACCOUNT_BALANCE_V2_URL = "/operations-order/v2/quara/account/balance";

//Endpoint for fetching the tracking summary
export const APP_REJECTIONS_TRACKING_SUMMARY_URL = "/operations/rejections/tracking-summary";

//Endpoint for fetching the loan rejection list
export const APP_TRACKING_LOAN_REJECTIONS_URL = "/operations/tracking/loan-rejections";

//Endpoint for fetching the registration rejection list
export const APP_TRACKING_REGISTRATION_REJECTIONS_URL = "/operations/tracking/registration-rejections";

//Endpoint for fetching the login statistics
export const APP_DASHBOARD_LOGIN_COUNT_URL = "/operations/app-login-statistics";

//Endpoint for fetching the registration statistics
export const APP_DASHBOARD_REGISTRATION_COUNT_URL = "/operations/app-registration-statistics";

//Endpoint for fetching the loan statistics
export const APP_DASHBOARD_LOANS_COUNT_URL = "/operations-order/v2/app/loan-statistics";

//Endpoint for fetching the loan statistics by category
export const APP_DASHBOARD_LOANS_BY_CATEGORY_COUNT_URL = "/operations-order/v2/app/loan-category-statistics";

//Endpoint for fetching the payment statistics
export const APP_DASHBOARD_PAYMENTS_COUNT_URL = "/operations-order/v2/app/payment-statistics";

//Endpoint for user status statistics
export const APP_USER_STATUS_STATISTICS_URL = "/operations/user-status-statistics";

//Endpoint for fetching the payouts
export const APP_PAYOUTS_URL = "/operations-order/v2/payouts";

//Endpoint for multiple payout updates
export const APP_MULTIPLE_PAYOUT_UPDATE = "/operations-order/v2/updatePayouts";

//Endpoint for multiple payout updates
export const APP_PAYMENT_URL = "/operations-order/v2/payment";

//Endopoint for update payment status
export const UPDATE_STATUS = "/operations-order/v2/updatePayment/"



//Endpoint for AML1 data 
export const GET_AML_URL = "/operations-order/v2/getAmlockData";

//Endpoint for AML1 STATUS update
export const AML_STATUS_URL = "/operations-order/v2/amlock-verification";

//Endpoint for AML financial Data 
export const AML_FINANCING_DATA = "/operations-order/v2/getAmlockLoanDetails?financialId="

//Endpoint for Payout Finance History
export const PAYOUT_FINANCING_HISTORY = "/operations-order/v2/payoutsHistory"



//Endpoint for PEP data
export const PEP_DATA = "/operations-order/v2/getPepData" 

//Endpoint for PEP Data Update
export const PEP_DATA_UPDATE_URL = "/operations-order/v2/updatePepData";

//Endpoint for PEP Data Update
export const PEP_STATUS_UPDATE_URL = "/operations-order/v2/updatePepStatus";

//Endpoint for PEP upload Doc
export const PEP_UPLOAD_DOCUMENT = "/operations-order/pep/document/store";

//Endpoint for PEP donwload Doc
export const PEP_DOWNLOAD_DOCUMENT = "/operations-order/pep/document/view";

//Endpoint for PEP Data history
export const PEP_DATA_HISTORY = "/operations-order/v2/getPepDataHistory";

/**
 * 
 * @param url - The url to be appended to the base url.
 * @returns - The full url.
 */
export function getFullURL(url: string) {
    return REACT_APP_BASE_URL + url;
}