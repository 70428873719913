import { useEffect, useMemo, useState } from "react";
import Card from "../../components/Cards";
import {
  fetchLoansByCategoryData,
  fetchLoansData,
  fetchLoginData,
  fetchPaymentsCategoryData,
  fetchUserStatusStatistics,
  fetchRegistrationData,
  userStatisticsSummary,
  LoanStatisticsSummary,
  LoginCountSummary,
  PayoutStatisticsSummary,
  RegistraionStatisticsSummary,
} from "./hooks/ScreenHelper";

//generad card data
const generateCardData = (
  count: number,
  title: string,
  incrementPer: number,
  data: any,
  showPerStats = false
) => {
  return {
    count,
    title,
    incrementPer,
    data,
    showPerStats: incrementPer > 0 || showPerStats ? true : false,
  };
};

const getIncrementPercentage = (value1, value2) => {
  if (!value2 || !value2 || value2 === 0) return 0;
  let value = value1 - value2 / value2;
  let finalPer = value * 100;
  return finalPer;
};

const getLoanCategoryCount = (data) => {
  let count = 0;
  (data || []).forEach((el) => {
    el.items.forEach((item) => {
      count += item.count;
    });
  });
  return count;
};

const DashPage = () => {
  const [loginStat, setLoginStat] = useState<LoginCountSummary>();
  const [registrationStats, setRegistrationStats] =
    useState<RegistraionStatisticsSummary>();
  const [loansStatData, setLoansStatData] = useState<LoanStatisticsSummary>();
  const [paymentsStatData, setPaymentsStatData] =
    useState<PayoutStatisticsSummary>();
  const [loansCategoryStatData, setLoansCategoryStatData] =
    useState<LoanStatisticsSummary>();
  const [userStatusStatistics, setUserStatusStatistics] =
    useState<userStatisticsSummary>();

  const userStatusCardData = useMemo(() => {
    const incPer = getIncrementPercentage(
      userStatusStatistics?.activeUserSummary?.PresentCount,
      userStatusStatistics?.activeUserSummary?.prevCount
    );
    return generateCardData(
      userStatusStatistics?.activeUserSummary.PresentCount,
      "User Statistics",
      incPer,
      userStatusStatistics,
      true
    );
  }, [userStatusStatistics]);

  const registrationCardData = useMemo(() => {
    const incPer = getIncrementPercentage(
      registrationStats?.registrationSummary.registrations,
      registrationStats?.registrationSummary.prevRegistration
    );
    return generateCardData(
      registrationStats?.registrationSummary.registrations,
      "Registration",
      incPer,
      registrationStats,
      true
    );
  }, [registrationStats]);

  const loginCardData = useMemo(() => {
    const incPer = getIncrementPercentage(
      loginStat?.logins,
      loginStat?.prevLogins
    );
    return generateCardData(
      loginStat?.logins,
      "Login Counts",
      incPer,
      loginStat,
      true
    );
  }, [loginStat]);

  const loansCardData = useMemo(() => {
    const loanCount =
      loansStatData?.completed +
      loansStatData?.pending +
      loansStatData?.rejected;
    return generateCardData(
      loanCount,
      "Loan Count",
      loansStatData?.completed,
      loansStatData
    );
  }, [loansStatData]);

  const loansCategoryCardData = useMemo(() => {
    const count = getLoanCategoryCount(loansCategoryStatData?.data);
    return generateCardData(
      count,
      "Loan Category",
      loansCategoryStatData?.completed,
      loansCategoryStatData
    );
  }, [loansCategoryStatData]);

  const paymentsCardData = useMemo(() => {
    const incPer = getIncrementPercentage(
      paymentsStatData?.installments,
      paymentsStatData?.prevInstallments
    );
    return generateCardData(
      paymentsStatData?.installments,
      "Payment Counts",
      incPer,
      paymentsStatData,
      true
    );
  }, [paymentsStatData]);

  useEffect(() => {
    fetchLoginData(setLoginStat);
    fetchRegistrationData(setRegistrationStats);
    fetchLoansData(setLoansStatData);
    fetchPaymentsCategoryData(setPaymentsStatData);
    fetchLoansByCategoryData(setLoansCategoryStatData);
    fetchUserStatusStatistics(setUserStatusStatistics);
  }, []);

  return (
    <div
      className="box_container_dashBoard"
      style={{ backgroundColor: "#EEEEEE" }}
    >
      <div className="row">
        <div className="col p-0 m-0">
          <Card cardModel={loginCardData} />
        </div>
        <div className="col p-0 m-0">
          <Card cardModel={registrationCardData} />
        </div>
        <div className="col p-0 m-0">
          <Card cardModel={loansCardData} />
        </div>
        <div className="col p-0 m-0">
          <Card cardModel={paymentsCardData} />
        </div>
        <div className="col p-0 m-0">
          <Card cardModel={loansCategoryCardData} />
        </div>
        <div className="col p-0 m-0">
          <Card cardModel={userStatusCardData} />
        </div>
      </div>
    </div>
  );
};

export default DashPage;
