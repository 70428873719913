import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { getAllSettings, saveSettings } from "./hook/API";
import { AppSetting, confirmation } from "./hook/ScreenHelper";

export const AppSettings: React.FC = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<AppSetting[]>([]);
  const [tempData, setTempData] = useState<AppSetting[]>([]);

  // we calling this function when we need to get all records fromdatabase
  const callRequest = async () => {
    // setData([])
    getAllSettings((success, data, error) => {
      if (success) {
        setData(data.sort((a, b) => (a.settingName > b.settingName ? 1 : -1)));
        setTempData([...data]);
      }
    });
  };

  const saveSettingsFun = (data) => {
    saveSettings(data, (res) =>
      res ? confirmation({ message: t("appSettingUpdated") }) : null
    );
  };

  useEffect(() => {
    callRequest();
  }, []);

  const handleChange = (event, key) => {
    setData((settings) =>
      settings?.map((list, index) =>
        index === key ? { ...list, settingValue: event.target.value } : list
      )
    );
  };

  const resetSettings = () => {
    setData(tempData.sort((a, b) => (a.settingName > b.settingName ? 1 : -1)));
  };

  return (
    <>
      <div className="box_container">
        <div className="row g-3">
          <div className="col-md-12">
            <h1>{t("App Settings")}</h1>
          </div>
          {data.map((_, key) => (
            <div className="col-md-12">
              <div className="form-group row">
                <label
                  htmlFor={"input-" + data[key].id}
                  className="col-sm-4 text-lowercase col-form-label"
                >
                  {data[key].settingName}
                </label>
                <div className="col-sm-8">
                  <input
                    name={"input-" + data[key].id}
                    type="text"
                    className="form-control"
                    id={"input-" + data[key].id}
                    value={data[key]?.settingValue}
                    placeholder={"Please enter " + data[key].settingName}
                    onChange={(e) => handleChange(e, key)}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="col-md-12">
            <button
              type="submit"
              className="btn btn-primary mb-2 mx-2"
              onClick={() => saveSettingsFun(data)}
              title="Save settings"
            >
              {t("buttons.save")}
            </button>
            <button
              type="submit"
              className="btn btn-secondary ms-2 mb-2"
              onClick={() => resetSettings()}
              title="Reset"
            >
              {t("buttons.reset")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
