import API from '../../../hooks/api';
import { filters, PEPDataList, PEPDataHistory } from './ScreenHelper';
import { getFullURL, PEP_DATA, PEP_STATUS_UPDATE_URL, PEP_DATA_UPDATE_URL, PEP_UPLOAD_DOCUMENT, PEP_DOWNLOAD_DOCUMENT, PEP_DATA_HISTORY } from '../../../utils/Endpoints';
const { REACT_APP_BASE_URL } = process.env;

export const getPEP = async (
        data: filters,
        callBack: (success: boolean, data: PEPDataList, errorMsg: string | null) => void = () => {}
    ) => {
    const paramsObj = getParamObj(data);
    await API.get(getFullURL(PEP_DATA), {params: paramsObj})
    .then((res) => {
        const data: PEPDataList = res.data;
        callBack(true, data, null);
        return res.data;
    }).catch((err) => {
        callBack(false, null, err);
    });
}


export const getPEPLogData = async (
    data: filters,
    callBack: (success: boolean, data: PEPDataList, errorMsg: string | null) => void = () => {}
) => {
    const paramsObj = getParamObj(data);
    await API.get(getFullURL(PEP_DATA), {params: paramsObj})
    .then((res) => {
        const data: PEPDataList = res.data;
        callBack(true, data, null);
        return res.data;
    }).catch((err) => {
        callBack(false, null, err);
    });
}

export const getPepDataHistory = async (
    data: PEPDataHistory,
    callBack: (success: boolean, data: PEPDataList, errorMsg: string | null) => void = () => {}
) => {
    console.table("data",data)
    const paramsObj = data;
    await API.get(getFullURL(PEP_DATA_HISTORY), {params: paramsObj})
    .then((res) => {
        const data: PEPDataList = res.data;
        callBack(true, data, null);
        return res.data;
    }).catch((err) => {
        callBack(false, null, err);
    });
}

export const updatePepData = async (
    data,
    callBack: (success: boolean, errorMsg: string | null) => void = () => {}
) => {
    const {loanId, nationalId, ...rest} = data;
    const flag = (rest?.status && (rest.status === "APPROVED" || rest.status === "REJECTED")) 
    
    await API.put(getFullURL(flag ? PEP_STATUS_UPDATE_URL : PEP_DATA_UPDATE_URL), rest)
        .then((res) => {
            callBack(true, null);
            return res.data;
        }).catch((err) => {
            callBack(false, err);
        });
}


export const updateDocument = async (
    data,
    callBack: (success: boolean, errorMsg: string | null) => void = () => {}
) => {
    await API.post(getFullURL(PEP_UPLOAD_DOCUMENT), data)
        .then((res) => {
            callBack(true, null);
            return res.data;
        }).catch((err) => {
            callBack(false, err);
        });
}

export const uploadDocument = async (
    data,
    callBack: (success: boolean, errorMsg: string | null) => void = () => {}
) => {
    await API.put(getFullURL(PEP_DATA_UPDATE_URL), data)
        .then((res) => {
            callBack(true, null);
            return res.data;
        }).catch((err) => {
            callBack(false, err);
        });
}

export const donwloadDocument = async (
    {nationalId, loanId},
    callBack: (success: boolean, errorMsg: string | null) => void = () => {}
) => {
    await API({url: `${REACT_APP_BASE_URL + PEP_DOWNLOAD_DOCUMENT}?nationalId=${nationalId}&loanId=${loanId}`, method: 'get', responseType: 'arraybuffer'})
        .then((res) => {
            var file = new Blob([res.data], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch((err) => {
            callBack(false, err);
        });
}

function getParamObj(data: filters) {
    const paramsObj = {
        page: data.page ?? 1,
        size: data.size ?? 10,
        searchText: data.searchText ?? "",
        loadAllDocuments: data.loadAllDocuments ?? false,
        pepStatus : data.pepStatus ?? "ALL",
        isPep : data.isPep ?? "ALL"
    };
    return paramsObj;
}

