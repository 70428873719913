import API from "../../../hooks/api";
import { ALL } from "../../../utils/Constants";
import {
  Payment,
  filters,
  PaymentData,
} from "./ScreenHelper";
import {
  APP_PAYMENT_URL,
  UPDATE_STATUS,
  getFullURL,
} from "../../../utils/Endpoints";
import { APP_MULTIPLE_PAYOUT_UPDATE } from "../../../utils/Endpoints";
const { REACT_APP_BASE_URL } = process.env;

export const getPayments = async (
  data: filters,
  callBack: (
    success: boolean,
    data: PaymentData,
    errorMsg: string | null
  ) => void = () => {}
) => {
  const paramsObj = getParamObj(data);
  await API.get(getFullURL(APP_PAYMENT_URL), { params: paramsObj })
    .then((res) => {
      const data: PaymentData = res.data;
      callBack(true, data, null);
      return res.data;
    })
    .catch((err) => {
      callBack(false, null, err);
    });
};

function getParamObj(data: filters) {
  const paramsObj = {
    page: data.page,
    size: data.pageSize ?? 10,
    searchText: data.search,
    loadAllDocuments: data.loadAllDocuments,
  };

  if (data.paymentStatus !== "" && data.paymentStatus !== "ALL") {
    paramsObj["PaymentStatus"] = data.paymentStatus;
  }

  return paramsObj;
}

