import React from "react";
import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import { Main } from "../routers/Main.router";

export const MainLayout: React.FC = () => {
  return (
    <>
      <div className="d-flex flex-column min-vh-100">
        <Header />
        <main>
          <section className="content-area">
            <div className="container">
              <Main />
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};
