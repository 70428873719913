import React, { useEffect, useState } from "react";
import { TableRowPlaceholder } from "../../components/TableRowPlaceholder/TableRowPlaceholder";
import {
  CustomerTrackingProps,
  geoMarker,
  paginationElemnts,
} from "./hook/ScreenHelper";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/images/pagination_arrow.svg";
import { useAppSelector } from "../../hooks/hooks";
import { getAllMarkers } from "./hook/API";
import { useLocation } from "react-router-dom";
import { Customer } from "../Customers/hook/ScreenHelper";
import { useNavigate } from "react-router-dom";
import { showTrackMap } from "./hook/ScreenHelper";
import Moment from "react-moment";

export const CustomersTrackerList: React.FC<CustomerTrackingProps> = (
  props
) => {
  const { t } = useTranslation();
  const { loading } = useAppSelector((state) => state.settings);
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<geoMarker[]>([]);
  const customerData = location.state as Customer;
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (customerData) {
      getAllMarkers(customerData.keycloakId, page, (success, data) => {
        if (success) {
          setData(data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const changePage = (currentPage: number) => {
    setPage(currentPage);
  };

  return (
    <>
      <div className="box_container">
        <div className="row g-3">
          <div className="col-md-12 ">
            <div className="table-responsive" style={{ minHeight: "25rem" }}>
              <div>
                {t("inputs.customer")}:{" "}
                <span className="text text-secondary">
                  {" "}
                  {customerData.fullName} {customerData.govId}
                </span>
              </div>
              <table className="table table-hover my-table">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: 100 }}>
                      {t("inputs.customers.createdAt")}
                    </th>
                    <th scope="col">{t("inputs.customers.deviceName")}</th>
                    <th scope="col">{t("inputs.customers.latitude")}</th>
                    <th scope="col">{t("inputs.customers.longitude")}</th>
                    <th scope="col">{t("inputs.customers.phoneIpAddress")}</th>
                    <th scope="col">{t("inputs.customers.operationType")}</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <TableRowPlaceholder />}

                  {data.map((item, key) => (
                    <tr key={item.id}>
                      <td>
                        <Moment format="YYYY-MM-DD HH:mm:ss" tz="Asia/Riyadh">
                          {item.createdAt}
                        </Moment>
                      </td>
                      <td>{item.deviceName}</td>
                      <td>{item.latitude}</td>
                      <td>{item.longitude}</td>
                      <td>{item.phoneIpAddress}</td>
                      <td>{item.operationType}</td>
                      <td>
                        <div className="btn btn-warning rounded">
                          <div
                            className=""
                            onClick={(e) => showTrackMap(item, navigate)}
                          >
                            Map
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 ">
              <div className="table-footer">
                <div className="results-count">
                  <p></p>
                </div>
                <div className="results-pagination">
                  <nav>
                    <ul className="pagination">
                      <li key={"9991"} className="page-item">
                        <button
                          className="prev page-link"
                          disabled={page <= 1}
                          onClick={() => changePage(page - 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                      {paginationElemnts(data, page, 8888, changePage)}
                      <li key={"9992"} className="page-item">
                        <button
                          className="next page-link"
                          disabled={data.length < 10}
                          onClick={() => changePage(page + 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
