import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import { CustomerData, exportToExcel } from "../hooks/Screenhelper";
import {
  ReportSummaryModel,
  filters,
  showDeactivationTrackingPopup,
} from "../hooks/Screenhelper";
import { callRegistrationRejectionRequest } from "../hooks/Screenhelper";
import Pagination from "../../../components/Pagination";
import { useTranslation } from "react-i18next";
import Filter from "../../../assets/images/filter.svg";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { getRejectionTrackingSummary } from "../hooks/API";
import { setReportSummary } from "../../../redux/reducers/reportsSlice";
import { store } from "../../../redux/store";

export const DeactivationReport: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { reportSummary } = useAppSelector((state) => state.reports);
  const [data, setData] = useState<CustomerData>(null);
  const [page, setPage] = useState<number>(1);

  const [filters, setFilters] = useState<filters>({
    page,
    loadDeactivatedCustomersOnly: true,
    loadAllDocuments: false,
  });

  const [input, setInput] = useState<filters>({
    city: "",
    country: "",
    reason: "",
  });

  useEffect(() => {
    callRegistrationRejectionRequest(setData, filters);
  }, [page, filters]);

  const changePage = (currentPage: number) => {
    setFilters({ ...filters, page: currentPage });
    setPage(currentPage);
  };

  const callRequest = (clear: boolean) => {
    setData(null);
    if (clear) {
      setInput((res) => ({ ...res, city: "", country: "", reason: "" }));
      setFilters((res) => ({
        ...res,
        city: "",
        country: "",
        reason: "",
        page: 1,
      }));
    } else {
      setFilters((res) => ({
        ...res,
        city: input.city,
        country: input.country,
        reason: input.reason,
        page: 1,
      }));
    }
    document.getElementById("closeCanvas").click();
  };

  useEffect(() => {
    getRejectionTrackingSummary(
      (success: boolean, data: ReportSummaryModel) => {
        if (success) {
          store.dispatch(setReportSummary(data));
        }
      }
    );
  }, []);

  return (
    <>
      <div className="box_container">
        <h3 className="text-center">
          {t("pages.deactivationReport.deactivation")}
          <span className="mx-2 badge bg-success">
            {reportSummary.deactivationReportCount}
          </span>
          <button
            className="btn bg-gradient-dark mx-2 badge bg-success p-2"
            style={{ padding: "10px" }}
            onClick={() => exportToExcel(filters, "DeactivationReport")}
            type="button"
            data-bs-toggle="offcanvas"
            aria-controls="offcanvasExample"
            data-bs-placement="top"
            title="Download"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </button>
          <button
            className="btn bg-gradient-dark mx-2 badge bg-success p-2"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
            data-bs-placement="top"
            title="Filter"
          >
            <img alt="" src={Filter} />
          </button>
        </h3>
        <div className="col-md-12">
          <div className=" table-responsive">
            <table className="table table-hover my-table">
              <thead>
                <tr>
                  <th scope="col">Date & time</th>
                  <th scope="col">NationalId</th>
                  <th scope="col">Country</th>
                  <th scope="col">City</th>
                  <th scope="col">Ip Address</th>
                  <th scope="col">Reason</th>
                </tr>
              </thead>
              <tbody>
                {data?.items?.map((item, index) => (
                  <tr
                    onClick={(e) =>
                      showDeactivationTrackingPopup(item, navigate)
                    }
                    key={index}
                  >
                    <td style={{ minWidth: 140 }}>
                      <Moment format="YYYY-MM-DD HH:mm:ss" tz="Asia/Riyadh">
                        {item.createdAt}
                      </Moment>
                    </td>
                    <td>{item?.govId}</td>
                    <td>{item?.country}</td>
                    <td>{item?.city}</td>
                    <td>{item?.phoneIpAddress}</td>
                    <td>{item?.deactivationReason}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            data={data?.items}
            changepage={changePage}
            page={page}
            length={data?.totalCount}
          />
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end offcanvas-filter"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            {t("filter") as string}
          </h5>
          <button
            type="button"
            id="closeCanvas"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="mb-3">
            <label
              htmlFor="examplehtmlFormControlInput1"
              className="htmlForm-label"
            >
              {t("inputs.reports.deactivationReport.city") as string}
            </label>
            <input
              type="text"
              value={input.city}
              className="form-select"
              id="examplehtmlFormControlInput1"
              placeholder={t(
                "inputs.reports.deactivationReport.placeholder.city"
              )}
              onChange={(e) => setInput({ ...input, city: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="examplehtmlFormControlInput2"
              className="htmlForm-label"
            >
              {t("inputs.reports.deactivationReport.country") as string}
            </label>
            <input
              type="text"
              value={input.country}
              className="form-select"
              id="examplehtmlFormControlInput2"
              placeholder={t(
                "inputs.reports.deactivationReport.placeholder.country"
              )}
              onChange={(e) => setInput({ ...input, country: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="examplehtmlFormControlInput3"
              className="htmlForm-label"
            >
              {
                t(
                  "inputs.reports.deactivationReport.reasonOfRejection"
                ) as string
              }
            </label>
            <input
              type="text"
              value={input.reason}
              className="form-select"
              id="examplehtmlFormControlInput3"
              placeholder={t(
                "inputs.reports.deactivationReport.placeholder.reasonOfRejection"
              )}
              onChange={(e) => setInput({ ...input, reason: e.target.value })}
            />
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="results">
            <strong> {data?.items?.length + " " + t("results")} </strong>
          </div>
          <div className="main-buttons">
            <button
              data-bs-dismiss="offcanvas"
              type="button"
              className="btn btn-light"
              onClick={async () => {
                callRequest(true);
              }}
            >
              {t("clear").toString()}
            </button>
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                callRequest(false);
              }}
            >
              {t("apply").toString()}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
