import { ALL } from "../../../utils/Constants";
import { getLoansLookupInfo } from "./API";
import exportFromJSON from "export-from-json";
import { executeGetRequest } from "../../../hooks/api";
import { APP_CREDIT_QUALITY_API_V2_URL } from "../../../utils/Endpoints";

export interface filters {
  requestType?: string;
  stage?: string;
  page?: number;
  search?: string;
  pageSize?: Number;
  loadAllDocuments?: boolean;
}

export interface LoanLookUpInfo {
  nationalId?: string;
  keycloakId: string;
  financialDataId: string;
  customerName: string;
  lastCashLoanStage: string;
  createdAt: string;
  reason: string;
  compApplId: string;
  ibanVerificationStatus: String;
  creditApprovalStatus: String;
  financeTransferStatus: String;
  deviceId?: String;
}

export interface LoanLookUpInfoData {
  loanLookups: LoanLookUpInfo[];
  totalCount: number;
}

/**
 * Model for credit quality data.
 */
export interface CreditQualityDataModel {
  id: string;
  nationality: string;
  appNo: string;
  age: number;
  dependentsCount: number;
  requestedLoanAmount: number;
  tenure: number;
  rate: number;
  totalCalculatedLivingExpenses: number;
  sector: string;
  agencyCode: string;
  payMonth: string;
  employmentStatus: boolean;
  dateOfJoining: string;
  basicWage: number;
  totalAllowance: number;
  housingAllowance: number;
  simahScore: number;
  simahCheckValid: boolean;
}

export const cashLoanStages = [
  ALL,
  "CREATED",
  "VERIFIED",
  "COMMODITY_PURCHASED",
  "APP_CUSTCARED",
  "CALL_INITIALIZED",
  "CALL_APPROVED",
  "NAFAES_SALES_ORDERED",
  "CONTRACT_SIGNED",
  "SANAD_CREATED",
  "DISBURSED",
  "WAITING_FOR_DISBURSED",
  "SOFT_REJECTED",
  "HARD_REJECTED",
  "LOCKED",
  "WITHDRAWAL_REQUESTED",
  "WITHDRAWAL_SUCCESS",
  "WITHDRAWAL_FAILED",
];

export const loanStages = [
  ALL,
  "ONGOING",
  "PROVENIR_ELIGIBLE",
  "INCOMPLETE",
  "PROVENIR_NOT_ELIGIBLE",
];

// we calling this function when we need to get all records fromdatabase
export const callRequest = async (
  setData: React.Dispatch<React.SetStateAction<LoanLookUpInfoData>>,
  filters: filters,
  initFilters?: filters
) => {
  setData(null);
  await getLoansLookupInfo(initFilters ?? filters, (_, data) => setData(data));
};

// Printing pagination items
export const paginationElemnts = (
  dataArray: LoanLookUpInfo[],
  currentPage: number,
  length: number,
  changePage: (i: number) => void
): JSX.Element[] => {
  let pages: JSX.Element[] = [];
  let totalPage = Math.ceil(length / 10);

  const first = (
    <li key={1} className={"page-item" + (1 === currentPage ? " active" : "")}>
      <button className="btn btn-link" onClick={(e) => changePage(1)}>
        1
      </button>
    </li>
  );
  const last = (
    <li
      key={totalPage}
      className={"page-item" + (totalPage === currentPage ? " active" : "")}
    >
      <button className="btn btn-link" onClick={(e) => changePage(totalPage)}>
        {totalPage}
      </button>
    </li>
  );

  if (currentPage - 1 > 1) pages.push(first);
  for (let i = currentPage - 1; i <= currentPage + 1; i++) {
    if (i > 0 && i <= totalPage) {
      const element = (
        <li
          key={i.toString()}
          className={"page-item" + (i === currentPage ? " active" : "")}
        >
          <button className="btn btn-link" onClick={(e) => changePage(i)}>
            {i}
          </button>
        </li>
      );
      pages.push(element);
    }
    if (i === currentPage && dataArray?.length < 10) break;
  }
  if (totalPage - currentPage > 1) pages.push(last);

  return pages;
};

/**
 * Exports the credit API data.
 * @param data
 * @param t
 */
export const exportCreditAPIData = async (t) => {
  const URL =
    APP_CREDIT_QUALITY_API_V2_URL + "?page=1&size=" + Number.MAX_VALUE;
  const creditQualityData = await executeGetRequest(URL);
  if (creditQualityData) {
    const fields = {
      id: t("pages.loans.id"),
      nationality: t("pages.loans.nationality"),
      appNo: t("pages.loans.appNo"),
      age: t("pages.loans.age"),
      dependentsCount: t("pages.loans.dependentsCount"),
      requestedLoanAmount: t("pages.loans.requestedLoanAmount"),
      tenure: t("pages.loans.tenure"),
      rate: t("pages.loans.rate"),
      totalCalculatedLivingExpenses: t(
        "pages.loans.totalCalculatedLivingExpenses"
      ),
      sector: t("pages.loans.sector"),
      agencyCode: t("pages.loans.agencyCode"),
      payMonth: t("pages.loans.payMonth"),
      employmentStatus: t("pages.loans.employmentStatus"),
      dateOfJoining: t("pages.loans.dateOfJoining"),
      basicWage: t("pages.loans.basicWage"),
      totalAllowance: t("pages.loans.totalAllowance"),
      housingAllowance: t("pages.loans.housingAllowance"),
      simahScore: t("pages.loans.simahScore"),
      simahCheckValid: t("pages.loans.simahCheckValid"),
    };
    const newData = [];
    creditQualityData.map((item: CreditQualityDataModel) => {
      return newData.push(item);
    });

    const fileName = "Credit-Quality-Data-";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({
      data: newData,
      fileName,
      exportType,
      fields,
      withBOM: true,
    });
  }
};

/**
 * Exports Data.
 * @param data
 * @param t
 */
export const exportData = async (filters: filters, t) => {
  await getLoansLookupInfo(
    { ...filters, loadAllDocuments: true },
    (success, data) => {
      if (success && data) {
        const fields = {
          createdAt: t("inputs.loans.createdAt"),
          customerName: t("inputs.loans.customerName"),
          nationalId: t("inputs.loans.nationalId"),
          financialDataId: t("inputs.loans.financialDataId"),
          compApplId: t("inputs.loans.compAppId"),
          lastCashLoanStage: t("inputs.loans.lastCashLoanStage"),
          reason: t("inputs.loans.reason"),
        };
        const newData = [];
        data.loanLookups.map((item: any) => {
          return newData.push({
            createdAt: item.createdAt,
            customerName: item.customerName,
            nationalId: item.nationalId,
            financialDataId: item.financialDataId,
            compApplId: item.compApplId,
            lastCashLoanStage: item.lastCashLoanStage,
            reason: item.reason,
          });
        });

        const fileName = "Loans-";
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({
          data: newData,
          fileName,
          exportType,
          fields,
          withBOM: true,
        });
      }
    }
  );
};
