import API from '../../../hooks/api';
import {CustomerRequest, filters, CustomerRequestData} from './ScreenHelper';

export const getAllIbanRequests = async (
        data: filters,
        callBack: (success: boolean, data: CustomerRequestData, errorMsg: string | null) => void = () => {}
    ) => {
    await API.get("/iban-operation/request/view", {params: {
        requestedAt: data.date,
        ibanStatus: data.status,
        bankName: data.bank,
        page: (data.page ?? 1),
        size: data.size ?? 10,
        searchText: data.search,
        includeRejectedLoans: data.includeRejectedLoans
    }})
    .then((res) => {
        callBack(true, res.data, null);
        return res.data;
    }).catch((err) => {
        callBack(false, null, err);
    });
}