import API from '../../../hooks/api';
import { APP_SETTINGS_V2_URL, APP_SETTINGS_V1_URL } from '../../../utils/Endpoints';
import { AppSetting } from './ScreenHelper';

export const getAllSettings = async (
    callBack: (success: boolean, data: AppSetting[], errorMsg: string | null) => void = () => {}
) => {
    await API.get(APP_SETTINGS_V2_URL)
    .then((res) => {
        callBack(true, res.data, null);
        return res.data;
    }).catch((err) => {
        callBack(false, [], err);
    });
}

export const saveSettings = async (
    settings: AppSetting[],
    callBack: (success: boolean, data: AppSetting[], errorMsg: string | null) => void = () => {}
) => {
    await API.put(APP_SETTINGS_V1_URL, settings)
    .then((res) => {
        callBack(true, res.data, null);
        return res.data;
    }).catch((err) => {
        callBack(false, [], err);
    });
}


