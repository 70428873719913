import exportFromJSON from "export-from-json";
import { NavigateFunction } from "react-router-dom";
import { setErrorMsg } from "../../../redux/reducers/settingsSlice";
import { ALL, PaymentStatus } from "../../../utils/Constants";
import { store } from "./../../../redux/store";
import { getPayments } from "./API";

export interface filters {
  date?: string;
  status?: string;
  page?: number;
  pageSize?: number;
  search?: string;
  includeRejectedLoans?: boolean;
  loadAllDocuments?: boolean;
  paymentStatus?: string;
}

export interface PaymentData {
  payments: Payment[];
  totalCount: number;
}

export interface Payment {
  amount?: number;
  checkoutId?: string;
  createdAt?: Date;
  createdTime?: string;
  currency?: string;
  installmentId?: string;
  keycloakId?: string;
  loanId?: string;
  modifiedAt?: string;
  nationalId?: string;
  objectId?: string;
  type?: string;
  status?: string;
}

export interface PaymentStatusRequestData {
  objectId: string;
}

export const paymentStatus = [
  PaymentStatus.ALL,
  PaymentStatus.SUCCESS,
  PaymentStatus.REJECTED,
  PaymentStatus.PENDING,
  PaymentStatus.INITIATED,
];

//confirmation message
export const updatePaymentMessage = (message) => {
  let reason = "";
  let blockTitle = "";
  const messageEle = (
    <div className="mb-3" style={{ textAlign: "start" }}>
      <h5 className="text-center text-warning">{message}</h5>
    </div>
  );

  store.dispatch(
    setErrorMsg({
      icon: null,
      acceptBtnTitle: "ok",
      modalContentClass: "small",
      acceptBtnAction: () => {
        window.location.reload();
      },
      title: blockTitle,
      message: messageEle,
    })
  );
};

// we calling this function when we need to get all records fromdatabase
export const callRequest = async (
  setData: React.Dispatch<React.SetStateAction<PaymentData>>,
  filters: filters,
  initFilters?: filters
) => {
  setData(null);
  await getPayments(initFilters ?? filters, (_, data) => setData(data));
};

// Printing pagination items
export const paginationElemnts = (
  dataArray: Payment[],
  currentPage: number,
  length: number,
  changePage: (i: number) => void
): JSX.Element[] => {
  let pages: JSX.Element[] = [];
  let totalPage = Math.ceil(length / 10);

  const first = (
    <li key={1} className={"page-item" + (1 === currentPage ? " active" : "")}>
      <button className="btn btn-link" onClick={(e) => changePage(1)}>
        1
      </button>
    </li>
  );
  const last = (
    <li
      key={totalPage}
      className={"page-item" + (totalPage === currentPage ? " active" : "")}
    >
      <button className="btn btn-link" onClick={(e) => changePage(totalPage)}>
        {totalPage}
      </button>
    </li>
  );

  if (currentPage - 1 > 1) pages.push(first);
  for (let i = currentPage - 1; i <= currentPage + 1; i++) {
    if (i > 0 && i <= totalPage) {
      const element = (
        <li
          key={i.toString()}
          className={"page-item" + (i === currentPage ? " active" : "")}
        >
          <button className="btn btn-link" onClick={(e) => changePage(i)}>
            {i}
          </button>
        </li>
      );
      pages.push(element);
    }
    if (i === currentPage && dataArray?.length < 10) break;
  }
  if (totalPage - currentPage > 1) pages.push(last);

  return pages;
};

// Open details popup
export const showDetails = (
  t,
  payment: Payment,
  navigate: NavigateFunction
) => {
  store.dispatch(
    setErrorMsg({
      icon: null,
      title: t("More Details"),
      message: (
        <div className="scroller-area" style={{ maxHeight: 550, padding: 20 }}>
          <div className="row">
            {printElements(t, payment).map((item) => item)}
          </div>
        </div>
      ),
    })
  );
};

const printElements = (t, dataArray: any): JSX.Element[] => {
  const array: JSX.Element[] = [];
  Object.keys(dataArray).forEach((key) => {
    array.push(
      <div className="col-12 col-md-6" key={key}>
        <div className="bordered-box">
          <strong>{t("inputs.payouts." + key).toString()}</strong>
          <span className="ms-2">
            {dataArray[key] === "" ||
            dataArray[key] === false ||
            dataArray[key] === undefined
              ? "-"
              : dataArray[key].toString()}
          </span>
        </div>
      </div>
    );
  });
  return array;
};

export const exportData = async (filters, t) => {
  await getPayments({ ...filters, loadAllDocuments: true }, (success, data) => {
    if (success && data && data.payments.length) {
      const fields = {
        amount: t("inputs.payments.amount"),
        checkoutId: t("inputs.payments.nationalId"),
        createdAt: t("inputs.date"),
        createdTime: t("inputs.payments.createdTime"),
        currency: t("inputs.payments.currency"),
        installmentId: t("inputs.payments.installmentId"),
        keycloakId: t("inputs.payments.keycloakId"),
        loanId: t("inputs.payments.loanId"),
        modifiedAt: t("inputs.payments.modifiedAt"),
        nationalId: t("inputs.payments.nationalId"),
        objectId: t("inputs.payments.objectId"),
        type: t("inputs.payments.type"),
        status: t("inputs.status"),
      };
      const newData = [];
      data.payments.map((item: Payment) => {
        return newData.push({
          amount: item.amount,
          checkoutId: item.checkoutId,
          createdAt: item.createdAt,
          createdTime: item.createdTime,
          currency: item.currency,
          installmentId: item.installmentId,
          keycloakId: item.keycloakId,
          loanId: item.loanId,
          modifiedAt: item.modifiedAt,
          nationalId: item.nationalId,
          objectId: item.objectId,
          type: item.type,
          status: item.status,
        });
      });
      const fileName = "Payments-";
      const exportType = exportFromJSON.types.csv;
      exportFromJSON({
        data: newData,
        fileName,
        exportType,
        fields,
        withBOM: true,
      });
    }
  });
};
