import exportFromJSON from "export-from-json";
import { NavigateFunction, Search } from "react-router-dom";
import { setErrorMsg } from "../../../redux/reducers/settingsSlice";
import { store } from "./../../../redux/store";
import { getAML, updateAMLStatus, requestFinancialData } from "./API";
import { AmlockStatus } from "./../../../utils/Constants";

export interface filters {
  AMLStatus?: string;
  page?: number;
  pageSize?: number;
  search?: string;
  includeRejectedLoans?: boolean;
  loadAllRecords?: boolean;
  nationalID?: string;
  amlockStage?: string;
}

export interface AMLData {
  requestId?: string;
  nationalId?: string;
  govId?: string;
  customerName?: string;
  nationality?: string;
  finantialDataId?: string;
  createdAt?: string;
  searchList?: string;
  financialDataId?: string;
  dateOfBirth?: string;
  searchListData?: DataItem[];
  message?: string;
  occupation?: string;
  employerName?: string;
  partyType?: string;
  amlockResult?: string;
  amlockStage?: string;
}

export interface addressInfo {
  additionalNumber?: number;
  address1?: string;
  address2?: string;
  buildingNumber?: number;
  city?: string;
  district?: string;
  isPrimaryAddress?: boolean;
  kCityCode?: string;
  kRegionCode?: string;
  latitude?: string;
  longitude?: string;
  objLatLng?: string;
  postCode?: number;
  regionName?: string;
  street?: string;
  unitNumber?: number;
}

export interface FinanacialData {
  iban?: string;
  ibanStatus?: string;
  accommodationOwnership?: boolean;
  accommodationType?: string;
  addressInfo?: addressInfo[];
  consent?: true;
  customerName?: string;
  decisionReason?: string;
  dependents?: number;
  dependentsHouseholdHelp?: number;
  dependentsPrivateSchool?: number;
  dependentsPublicSchool?: number;
  finalLoanAmount?: number;
  homeOwnership?: string;
  interest?: number;
  interestRate?: number;
  isAmlockVerified?: boolean;
  isBreadWinner?: boolean;
  isCustomerResponsibleForPayment?: boolean;
  isPoliticalPerson?: boolean;
  isQuaraEmployee?: boolean;
  isQuaraEmployeeRelative?: boolean;
  isRedfCustomer?: boolean;
  loanAmount?: number;
  loanProduct?: string;
  loanPurposeId?: string;
  monthlyIncome?: number;
  monthlyInstallmentAmount?: number;
  nafaesSaleConfirmation?: boolean;
  nationalId?: string;
  offerType?: string;
  principle?: number;
  requestedAmount?: number;
  responsibleForPaymentPersonName?: string;
  sector?: string;
  tenure?: number;
}

interface DataItem {
  type: string;
  id: string;
  name: string;
  riskScore: number;
}

export interface AMLDataList {
  AMLockResponse: AMLData[];
  totalCount: number;
}

export const callRequestFinancialId = async (setFinancialData, financialId) => {
  setFinancialData({});
  await requestFinancialData(financialId, (_, data) => {
    setFinancialData(data);
  });
};

// we calling this function when we need to get all records fromdatabase
export const callRequest = async (
  setData: React.Dispatch<React.SetStateAction<AMLDataList>>,
  filters: filters,
  initFilters?: filters
) => {
  setData(null);
  await getAML(initFilters ?? filters, (_, data) => {
    // removing APPROVED_BY_AMLOCK, sorting according to Date in descending order and seperating amlockStage = NEED_COMPLIANCE_ACTION and other amlockStage
    const finalData = data.AMLockResponse.filter(
      (res) => res.amlockStage !== "APPROVED_BY_AMLOCK"
    )
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .reduce((result, res) => {
        if (res.amlockStage === "NEED_COMPLIANCE_ACTION") {
          result.unshift(res);
        } else {
          result.push(res);
        }
        return result;
      }, []);

    setData({
      ...data,
      AMLockResponse: finalData,
      totalCount: finalData.length,
    });
  });
};

// Open AML confirm popup
export const updateAmlStatusPopUp = (data, refresshData: () => void) => {
  let blockTitle = "Are you sure you want to";
  const myClass =
    data.status === AmlockStatus.APPROVED ? "text-success " : "text-danger";
  const messageEle = (
    <div
      className={"my-3 py-2 fw-bold " + myClass}
      style={{
        textAlign: "center",
        fontSize: 30,
      }}
    >
      {data.status === "APPROVED" ? "APPROVE" : "REJECT"}
    </div>
  );

  store.dispatch(
    setErrorMsg({
      icon: null,
      cancelBtnTitle: "Cancel",
      acceptBtnTitle: "Update",
      modalContentClass: "small",
      acceptBtnAction: () => {
        updateAMLStatus(data, (success) => {
          if (success) {
            message("Updated successful");
          } else {
            message("Something went wrong");
          }
        });
      },
      title: blockTitle,
      message: messageEle,
    })
  );
};

const message = (data) => {
  store.dispatch(
    setErrorMsg({
      icon: null,
      closeBtn: false,
      acceptBtnTitle: "OK",
      modalContentClass: "small",
      acceptBtnAction: () => {
        window.location.reload();
      },
      title: "Status",
      message: data,
    })
  );
};

// Printing pagination items
export const paginationElemnts = (
  dataArray: AMLData[],
  currentPage: number,
  length: number,
  changePage: (i: number) => void
): JSX.Element[] => {
  let pages: JSX.Element[] = [];
  let totalPage = Math.ceil(length / 10);

  const first = (
    <li key={1} className={"page-item" + (1 === currentPage ? " active" : "")}>
      <button className="btn btn-link" onClick={(e) => changePage(1)}>
        1
      </button>
    </li>
  );
  const last = (
    <li
      key={totalPage}
      className={"page-item" + (totalPage === currentPage ? " active" : "")}
    >
      <button className="btn btn-link" onClick={(e) => changePage(totalPage)}>
        {totalPage}
      </button>
    </li>
  );

  if (currentPage - 1 > 2) pages.push(first);
  for (let i = currentPage - 1; i <= currentPage + 1; i++) {
    if (i > 0 && i <= totalPage) {
      const element = (
        <li
          key={i.toString()}
          className={"page-item" + (i === currentPage ? " active" : "")}
        >
          <button className="btn btn-link" onClick={(e) => changePage(i)}>
            {i}
          </button>
        </li>
      );
      pages.push(element);
    }
    if (i === currentPage && dataArray?.length < 10) break;
  }
  if (totalPage - currentPage > 2) pages.push(last);

  return pages;
};

// Open details popup
export const showDetails = (t, AML: AMLData, navigate: NavigateFunction) => {
  store.dispatch(
    setErrorMsg({
      icon: null,
      title: t("More Details"),
      message: (
        <div className="scroller-area" style={{ maxHeight: 550, padding: 20 }}>
          <div className="row">{printElements(t, AML).map((item) => item)}</div>
        </div>
      ),
    })
  );
};

const printElements = (t, dataArray: any): JSX.Element[] => {
  const array: JSX.Element[] = [];
  Object.keys(dataArray).forEach((key) => {
    array.push(
      <div className="col-12 col-md-6" key={key}>
        <div className="bordered-box">
          <strong>{t("inputs.payouts." + key).toString()}</strong>
          <span className="ms-2">
            {dataArray[key] === "" ||
            dataArray[key] === false ||
            dataArray[key] === undefined
              ? "-"
              : dataArray[key].toString()}
          </span>
        </div>
      </div>
    );
  });
  return array;
};

export const exportData = async (filters, t) => {
  await getAML({ ...filters, loadAllRecords: true }, (success, data) => {
    if (success && data && data?.AMLockResponse?.length) {
      const fields = {
        createdAt: t("inputs.aml.createdAt"),
        customerName: t("inputs.aml.name"),
        dateOfBirth: t("inputs.aml.dob"),
        financialDataId: t("inputs.aml.finantialDataId"),
        govId: t("inputs.aml.govId"),
        message: t("inputs.aml.message"),
        nationality: t("inputs.aml.nationality"),
        requestId: t("inputs.aml.requestId"),
        nationalId: t("inputs.aml.nationalId"),
        occupation: t("inputs.aml.occupation"),
        employerName: t("inputs.aml.employerName"),
        partyType: t("inputs.aml.partyType"),
        stage: t("inputs.aml.stage"),
      };
      const newData = [];
      data?.AMLockResponse?.map((item: AMLData) => {
        return newData.push({
          createdAt: item.createdAt ? item.createdAt : "",
          customerName: item.customerName ? item.customerName : "",
          dateOfBirth: item.dateOfBirth ? item.dateOfBirth : "",
          financialDataId: item.financialDataId ? item.financialDataId : "",
          govId: item.govId ? item.govId : "",
          message: item.message ? item.message : "",
          nationality: item.nationality ? item.nationality : "",
          requestId: item.requestId ? item.requestId : "",
          occupation: item.occupation ? item.occupation : "",
          employerName: item.employerName ? item.employerName : "",
          partyType: item.partyType ? item.partyType : "",
          stage: item.amlockStage ? item.amlockStage : "",
        });
      });

      const fileName = "AML_Phase_1 -";
      const exportType = exportFromJSON.types.csv;
      exportFromJSON({
        data: newData,
        fileName,
        exportType,
        fields,
        withBOM: true,
      });
    }
  });
};
