import React, { useRef } from 'react';

import { CustomerTrackingProps, stateType } from './hook/ScreenHelper';

import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const CustomersTracker: React.FC<CustomerTrackingProps> = (props) => {
    const googleMapRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    console.log("location",location.pathname);

    let mapHeight = '100vh';
    let data;
    //Get data from props way
    if (props.locationData) {
      data = props.locationData as stateType;
      //Set the map height for pop dialog state.
      mapHeight = '40vh';
    } else { //Get data from location 
      data = location.state as stateType;
    }

    let points;  // Read values passed on state
    if (data) {
      points = data.points;
    }

    useEffect(() => {
      console.log(points)
      const googleMapScript = document.createElement('script');
      googleMapScript.src=`https://maps.googleapis.com/maps/api/js?key=AIzaSyA86DtfVHobMsZnLamSdJtaPrb-FoKFltM&libraries=places`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener('load', () => {
        if (points) {
          getLatLng();
        }

      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[points]);

    if(!points || !points.length) {
      return (
        <div>
          Customers location data is not available.
        </div>
      );
    }

    const createGoogleMap = () => {
      return new window.google.maps.Map(googleMapRef.current, {
          zoom: 8,
          center: {
              lat: points[0].lat,
              lng: points[0].lng,
          },
          disableDefaultUI: true,
      })
  };

  const getLatLng = () => {
    const googleMap = createGoogleMap();
    points.forEach((item, i) => {
      new window.google.maps.Marker({
        position: { lat: item.lat, lng: item.lng },
        map: googleMap,
        animation: window.google.maps.Animation.DROP,
      });
    })
    

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    directionsRenderer.setMap(googleMap);
    calculateAndDisplayRoute(directionsService, directionsRenderer);
  };


const calculateAndDisplayRoute = (
  directionsService: google.maps.DirectionsService,
  directionsRenderer: google.maps.DirectionsRenderer
) => {
  const waypts: google.maps.DirectionsWaypoint[] = [];

  for (let i = 0; i < points.length; i++) {
    if(i !== 0 || i !== (points.length -1)){
      waypts.push({
        location: new google.maps.LatLng(points[i].lat, points[i].lng),
        stopover: true,
      });
    }
  }

  directionsService
    .route({
      origin: points[0],
      destination: points[points.length - 1],
      waypoints: waypts,
      optimizeWaypoints: false,
      travelMode: google.maps.TravelMode.DRIVING,
    })
    .then((response) => {
      directionsRenderer.setDirections(response);

      const route = response.routes[0];
      const summaryPanel = document.getElementById(
        "directions-panel"
      ) as HTMLElement;

      summaryPanel.innerHTML = "";

      // For each route, display summary information.
      for (let i = 0; i < route.legs.length; i++) {
        const routeSegment = i + 1;

        summaryPanel.innerHTML +=
          "<b>Route Segment: " + routeSegment + "</b><br>";
        summaryPanel.innerHTML += route.legs[i].start_address + " to ";
        summaryPanel.innerHTML += route.legs[i].end_address + "<br>";
        summaryPanel.innerHTML += route.legs[i].distance!.text + "<br><br>";
      }
    })
    .catch((e) => {
      console.log(e)
      window.alert("Directions request failed due to " )
    });
}
    return (
        <>
            <div className="box_container">
                {(location.pathname == "/customers/map") && 
                  <button onClick={() => navigate(-1)} className="btn btn-primary rounded-0">
                    Back
                  </button>
                }
                <div className="row g-3">
                    <div className="col-md-12 ">
                        <div style={{ height: mapHeight, width: '100%' }}>
                          <div
                            id="google-map"
                            ref={googleMapRef}
                            style={{ width: '100%', height: '100%' }}/>
                        </div>
                    </div>
                    <div className="col-md-12 ">
                      <div id="directions-panel"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

