import React from "react";
import Arrow from "../../assets/images/pagination_arrow.svg";
import { paginationElemnts } from "../../pages/CustomersTracker/hook/ScreenHelper";

const Pagination = ({ data, changepage, length, page }) => {
  console.log("data", page, length);
  return (
    <div className="col-md-12 ">
      <div className="table-footer">
        <div className="results-count">
          <p></p>
        </div>
        <div className="results-pagination">
          <nav>
            <ul className="pagination">
              <li key={"9991"} className="page-item">
                <button
                  className="prev page-link"
                  disabled={page <= 1}
                  onClick={() => changepage(page - 1)}
                >
                  <img alt="" src={Arrow} />
                </button>
              </li>
              {paginationElemnts(data, page, length, changepage)}
              <li key={"9992"} className="page-item">
                <button
                  className="next page-link"
                  disabled={data?.length < 10 || page === length / 10}
                  onClick={() => changepage(page + 1)}
                >
                  <img alt="" src={Arrow} />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
